//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: #034991;
$color-secondary: #D8E4EB;
$color-tertiary: #F5F5F5;
$color-quaternary: #E9F2F8;
$color-button: #E5213C;
$color-gradient: #017FC4;
$color-gradient-secondary: #007EC3;

$color-error: #E5213C;
$color-form-borders: $color-primary;
$color-menu-bg: $color-white;

//Text colors
$color-text: #465456;
$color-text-light: #889699;

//Font variables
$mainfont: 'Outfit', sans-serif;

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-h1-max: ceil(($default-size * 5.25)); // ~84px
$heading-h1-min: ceil(($default-size * 2.25)); // ~36px
$heading-h2-max: ceil(($default-size * 2.625)); // ~42px
$heading-h2-min: ceil(($default-size * 1.875)); // ~30px
$heading-h3-max: ceil(($default-size * 2)); // ~32px
$heading-h3-min: ceil(($default-size * 1.875)); // ~30px
$heading-h4-max: ceil(($default-size * 1.5)); // ~24px
$heading-h4-min: ceil(($default-size * 1.25)); // ~20px
$heading-h5: ceil(($default-size * 1.25)); // ~20px
$heading-h6: ceil(($default-size * 1.125)); // ~18px

//Text
$font-xl: ceil(($default-size * 1.375)); // ~22px
$font-l: ceil(($default-size * 1.1875)); // ~19px
$font-m: ceil(($default-size * 1.125)); // ~18px
$font-s: ceil(($default-size * 1)); // ~16px
$font-xs: ceil(($default-size * 0.75)); // ~12px
$font-menu: ceil(($default-size * 1.25)); // ~20px
$font-form: ceil(($default-size * 1.0625)); // ~17px
$font-hamburger: ceil(($default-size * 0.5625)); // ~9px

//Other text styles
$font-quote-max: ceil(($default-size * 2)); // ~32px
$font-quote-min: ceil(($default-size * 1.625)); // ~26px
$font-history-title-max: ceil(($default-size * 1.6875)); // ~27px
$font-history-title-min: ceil(($default-size * 1.375)); // ~22px

//Font settings
$text-settings: (
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $semibold,
                line-height: 110%,
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $semibold,
                line-height: 102%,
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: $heading-h5,
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: $heading-h6,
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-text-xl': (
                font-family: $mainfont,
                font-size: $font-xl,
                font-weight: $regular,
                line-height: 125%,
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: $font-l,
                font-weight: $light,
                line-height: 125%,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: $font-m,
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-text-xs': (
                font-family: $mainfont,
                font-size: $font-xs,
                font-weight: $regular,
                line-height: 125%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-history': (
                font-family: $mainfont,
                font-size: clamp($font-history-title-min, 4vw, $font-history-title-max),
                font-weight: $regular,
                line-height: 125%,
        )
);

;@import "sass-embedded-legacy-load-done:9";